
import { defineComponent } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TsxassAbility } from '@/helpers/constants';
import PdfGenerationProgressOverlay from '@/components/analytics/pdf/PdfGenerationProgressOverlay.vue';
import CreatePdf from '@/components/analytics/pdf/CreatePdf.vue';
import { LOAD_PROFILE } from '@/plugins/vuex/modules/profile/actionTypes';
import { LOAD_FEATURE_FLAGS } from '@/plugins/vuex/modules/featureFlags/actionTypes';
import { LOAD_ENGAGEMENT_INFO } from '@/plugins/vuex/modules/engagementInfo/actionTypes';

// TODO при переходе на другую страницу показывать диалог с предупреждением

export default defineComponent({
  name: 'ExportPdfLayout',

  components: {
    PdfGenerationProgressOverlay,
    CreatePdf,
  },

  data() {
    return {
      progress: 0,
      isError: false,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('engagementInfo', {
      user: 'user',
    }),
    surveyId(): number {
      return Number(this.$route.params.surveyId) || 0;
    },
    userId(): string | undefined {
      return (this.can(TsxassAbility.CAN_VIEW_EMPLOYEES_ANALYTICS) && this.$route.query?.userId)
        || this.user?.userId;
    },
  },

  async mounted() {
    await this[LOAD_FEATURE_FLAGS]();
    await this[LOAD_PROFILE]({ disableHelpdesk: true });
    await this[LOAD_ENGAGEMENT_INFO]();
  },

  methods: {
    ...mapActions('profile', [LOAD_PROFILE]),
    ...mapActions('engagementInfo', [LOAD_ENGAGEMENT_INFO]),
    ...mapActions('featureFlags', [LOAD_FEATURE_FLAGS]),
  },
});
