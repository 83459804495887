
import { defineComponent } from 'vue';
import PdfTitlePageRenderer from '@/components/analytics/pdf/renderers/PdfTitlePageRenderer.vue';
import PdfGeneralIndicatorsRenderer from '@/components/analytics/pdf/renderers/PdfGeneralIndicatorsRenderer.vue';
import PdfJohariSectionRenderer from '@/components/analytics/pdf/renderers/PdfJohariSectionRenderer.vue';
import PdfMetodologyDescriptionRenderer from '@/components/analytics/pdf/renderers/PdfMetodologyDescriptionRenderer.vue';
import PdfGeneralQuestionsRenderer from '@/components/analytics/pdf/renderers/PdfGeneralQuestionsRenderer.vue';
import PdfDestructorsRenderer from '@/components/analytics/pdf/renderers/PdfDestructorsRenderer.vue';
import PdfGeneralQuestionsHeatMapRenderer from '@/components/analytics/pdf/renderers/PdfGeneralQuestionsHeatMapRenderer.vue';
import PdfDestructorBarsRenderer from '@/components/analytics/pdf/renderers/PdfDestructorBarsRenderer.vue';
import PdfAnswersToOpenQuestionsRenderer from '@/components/analytics/pdf/renderers/PdfAnswersToOpenQuestionsRenderer.vue';
import PdfAdditionalQuestionsHeatMapRenderer from '@/components/analytics/pdf/renderers/PdfAdditionalQuestionsHeatMapRenderer.vue';
import PdfAnswersDistributionRenderer from '@/components/analytics/pdf/renderers/PdfAnswersDistributionRenderer.vue';
import PdfAnswersDynamicsRenderer from '@/components/analytics/pdf/renderers/PdfAnswersDynamicsRenderer.vue';
import PdfAnswersToGeneralQuestionsRenderer from '@/components/analytics/pdf/renderers/PdfAnswersToGeneralQuestionsRenderer.vue';
import { jsPDF as JsPdf } from 'jspdf';
import {
  V1EntitiesUsersPublicShowUser,
  V1EntitiesSurveysPublicShow,
  V1EntitiesAnalyticsSurveysPagesPage,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';

enum QuestionGroupAlias {
  GENERAL = 'general',
  EXTRA = 'extra',
  OPEN = 'open',
  DESTRUCTOR = 'destructor',
}

interface Renderer {
  component: any,
  exclude?: boolean,
  props?: Record<string, any>,
  pageNumber?: number,
}

export default defineComponent({
  name: 'CreatePdf',

  props: {
    userId: {
      type: String,
      required: true,
    },
    surveyId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      // Данные об опросе
      survey: null as V1EntitiesSurveysPublicShow | null,
      // Данные о сотруднике
      userData: null as V1EntitiesUsersPublicShowUser | null,
      // Страницы, доступные для отображения в PDF
      pages: [] as V1EntitiesAnalyticsSurveysPagesPage[],
      // Индекс текущего рендерера
      currentRendererIndex: 0,
      // Сохраняем тут картинки локально
      images: [] as string[],
      // При генерации возникла ошибка
      isError: false,
      // Генерация успешно завершена
      isComplete: false,
    };
  },

  computed: {
    renderStartPageNumber(): number {
      return Number(this.$route.query.start) || 0;
    },
    renderPagesCount(): number {
      return Number(this.$route.query.count) || 0;
    },
    surveyeeName(): string {
      if (!this.userData) {
        return '';
      }

      return `${this.userData.lastName} ${this.userData.firstName}`;
    },
    renderersList(): Renderer[] {
      const questionGroups: QuestionGroupAlias[] = this.pages.map((item) => item.alias as QuestionGroupAlias);
      const questionGroupsMap: Partial<Record<QuestionGroupAlias, V1EntitiesAnalyticsSurveysPagesPage>> = Object
        .fromEntries(this.pages.map((item) => [item.alias as QuestionGroupAlias, item]));

      return [
        {
          component: PdfTitlePageRenderer,
          props: {
            surveyName: this.survey?.name,
          },
        },
        {
          component: PdfMetodologyDescriptionRenderer,
        },
        {
          component: PdfGeneralQuestionsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.GENERAL),
          props: {
            groups: questionGroupsMap?.[QuestionGroupAlias.GENERAL]?.groups,
            questions: questionGroupsMap?.[QuestionGroupAlias.GENERAL]?.questions,
          },
        },
        {
          component: PdfDestructorsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.DESTRUCTOR),
          props: {
            answers: questionGroupsMap?.[QuestionGroupAlias.DESTRUCTOR]?.answers,
            pageTitle: questionGroupsMap?.[QuestionGroupAlias.DESTRUCTOR]?.name,
          },
        },
        {
          component: PdfGeneralIndicatorsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.GENERAL),
        },
        {
          component: PdfJohariSectionRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.GENERAL),
        },
        {
          component: PdfGeneralQuestionsHeatMapRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.GENERAL),
        },
        {
          component: PdfDestructorBarsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.DESTRUCTOR),
          props: {
            pageTitle: questionGroupsMap?.[QuestionGroupAlias.DESTRUCTOR]?.name,
          },
        },
        {
          component: PdfAdditionalQuestionsHeatMapRenderer,
          props: {
            surveyName: this.survey?.name,
          },
        },
        {
          component: PdfAnswersDistributionRenderer,
          props: {
            surveyName: this.survey?.name,
          },
        },
        {
          component: PdfAnswersDynamicsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.EXTRA),
          props: {
            surveyName: this.survey?.name,
          },
        },
        {
          component: PdfAnswersToGeneralQuestionsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.GENERAL),
        },
        {
          component: PdfAnswersToOpenQuestionsRenderer,
          exclude: !questionGroups.includes(QuestionGroupAlias.OPEN),
        },
      ].filter((item) => !item.exclude);
    },
    currentRenderer(): Renderer | null {
      const renderer = this.renderersList[this.currentRendererIndex];
      if (!renderer) {
        return null;
      }

      return {
        ...renderer,
        props: {
          ...(renderer?.props || {}),
          pageNumber: this.images.length,
          // Общие для всех рендереров пропсы
          surveyeeName: this.surveyeeName,
          surveyId: this.surveyId,
          userId: this.userId,
          renderStartPageNumber: this.renderStartPageNumber,
          renderPagesCount: this.renderPagesCount,
        },
      };
    },
    // Значение прогресса выполнения (0 - 100)
    currentProgressValue(): number {
      if (this.isComplete) {
        return 100;
      }

      return Math.round((this.currentRendererIndex / this.renderersList.length) * 100);
    },
  },

  watch: {
    currentProgressValue(newValue) {
      this.$emit('update:progress', newValue);
    },
  },

  mounted() {
    this.loadUserData();
    this.loadSurvey();
    this.loadPdfPages();
  },

  methods: {
    async loadUserData() {
      try {
        const { data } = await tsxassApi.getV1UsersId(this.userId);
        this.userData = data?.user || null;
      } catch (err) {
        this.$emit('error');
      }
    },
    async loadSurvey() {
      try {
        const { data: survey } = await tsxassApi.getV1SurveysSurveyId(this.surveyId, this.userId);
        this.survey = survey;
      } catch (err) {
        this.$emit('error');
      }
    },
    async loadPdfPages() {
      try {
        const { data: { pages } } = await tsxassApi.getV1AnalyticsSurveysSurveyIdPages(
          this.surveyId, this.userId || undefined,
        );
        this.pages = pages;
      } catch (err) {
        this.$emit('error');
      }
    },
    onRendererSuccess(event: string[]) {
      if (this.isError) {
        return;
      }

      // картинки могут и не прийти, поэтому надо проверить, есть ли что-то там
      if (event) {
        this.images.push(...event);
      }

      if (this.currentRendererIndex === this.renderersList.length - 1) {
        this.createPdf();
        return;
      }

      this.currentRendererIndex += 1;
    },
    onRendererError() {
      this.isError = true;
      this.$emit('error');
    },
    createPdf() {
      const doc = new JsPdf({ orientation: 'portrait' });
      this.images.filter(Boolean).forEach((image, index) => {
        if (index !== 0) doc.addPage();
        doc.addImage(image, 'png', 0, 0, 210, 297, String(index), 'SLOW');
      });
      this.isComplete = true;
      this.$emit('success');
      doc.save(`pdf ${new Date().toDateString()}`);
    },
  },
});
