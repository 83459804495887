
import { defineComponent } from 'vue';
import type { TranslateResult } from 'vue-i18n';

enum PDFStatus {
  IN_PROGRESS = 0,
  SUCCESS = 1,
  FAILED = 2
}

interface StatusData {
  icon: string,
  iconColor: string,
  title: string | TranslateResult,
  description: string | TranslateResult,
}

export default defineComponent({
  name: 'PdfGenerationProgressOverlay',

  props: {
    // Значение прогресса (0 - 100)
    progress: {
      type: Number,
      default: 0,
    },
    isError: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    statusesData(): StatusData[] {
      return [
        {
          icon: 'fa fa-circle-exclamation',
          iconColor: 'tt-light-yellow',
          title: this.$t('analytics.pdf.attentionDontCloseThePage'),
          description: this.$t('analytics.pdf.iAmAlreadyCompilingYourReport'),
        },
        {
          icon: 'fa fa-check-circle',
          iconColor: 'tt-light-green',
          title: this.$t('analytics.pdf.yourReportIsReady'),
          description: this.$t('analytics.pdf.checkYourDownloads'),
        },
        {
          icon: 'fa fa-circle-exclamation',
          iconColor: 'tt-light-red vibrant',
          title: this.$t('analytics.pdf.attentionUnableToGenerateDocument'),
          description: this.$t('analytics.pdf.tryAgainLater'),
        },
      ];
    },
    currentStatusData(): StatusData {
      let status: number;
      if (this.isError) {
        status = PDFStatus.FAILED;
      } else {
        status = this.progress < 100 ? PDFStatus.IN_PROGRESS : PDFStatus.SUCCESS;
      }
      return this.statusesData[status];
    },
  },
});
